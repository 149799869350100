import Vue from "vue";
import VueRouter from "vue-router";
import { isSignedIn } from "../auth";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: () => import("@/components/RouterHome.vue"),
    children: [
      {
        path: "/",
        name: "Home",
        component: () => import("@/components/TrueHome.vue"),
      },
      {
        path: "/mapa",
        name: "HomeMapa",
        component: () => import("@/components/Home.vue"),
        children: [
          {
            path: ":id",
            component: () => import("@/components/MarkerDialog.vue"),
            props: true,
            meta: {
              showModal: true,
            },
          },
        ],
      },
      {
        path: "/sobre",
        name: "Sobre",
        component: () => import("@/components/Sobre.vue"),
      },
      {
        path: "/leiloes",
        name: "Leilões",
        component: () => import("@/components/Leiloes.vue"),
      },
      {
        path: "/registrar",
        name: "Registrar",
        component: () => import("@/components/Registrar.vue"),
      },
      {
        path: "/imprensa",
        name: "Imprensa",
        component: () => import("@/components/Imprensa.vue"),
      },
      {
        path: "/doar",
        name: "Doar",
        component: () => import("@/components/Doar.vue"),
      },
      {
        path: "/registrar",
        name: "Registrar",
        component: () => import("@/components/Registrar.vue"),
      },
    ],
  },
  {
    path: "/app",
    name: "RouterView",
    component: () => import("@/views/RouterView.vue"),
    beforeEnter(_, __, next) {
      if (isSignedIn()) {
        next();
        return;
      }
      next("/login");
    },
    children: [
      {
        path: "",
        name: "AppHome",
        component: () => import("@/components/AppHome.vue"),
      },
      {
        path: "meu-perfil",
        name: "MeuPerfil",
        component: () => import("@/components/MeuPerfil.vue"),
      },
      {
        path: "registrar",
        name: "Registrar Usuario",
        component: () => import("@/components/RegistrarUsuario.vue"),
      },
      {
        path: "moderador",
        name: "Moderador",
        component: () => import("@/components/moderador/ModeradorWrapper.vue"),
        meta: { key: "moderador" },
        children: [
          {
            path: "/",
            name: "Moderador",
            component: () =>
              import("@/components/moderador/ModeradorIndex.vue"),
          },
          {
            path: "/app/usuario/:id",
            name: "Usuario",
            component: () => import("@/components/moderador/Usuario.vue"),
          },
          {
            path: "/app/moderador/cadastros",
            name: "Cadastros",
            component: () => import("@/components/moderador/Cadastros.vue"),
            children: [],
          },
          {
            path: "/app/moderador/exportar",
            name: "Exportar",
            component: () => import("@/components/moderador/Exportar.vue"),
          },
          {
            path: "/app/moderador/denuncias-analisar",
            name: "DenunciasAnalisar",
            component: () =>
              import("@/components/moderador/DenunciasAnalisar.vue"),
          },
          {
            path: "/app/moderador/denuncias-publicadas",
            name: "DenunciasPublicadas",
            component: () =>
              import("@/components/moderador/DenunciasPublicadas.vue"),
          },
          // {
          //   path: '/app/moderador/denuncias-encaminhadas',
          //   name: 'DenunciasEncaminhadas',
          //   component: () => import('@/components/moderador/DenunciasEncaminhadas.vue'),
          // }
        ],
      },
      {
        path: "campo",
        name: "Campo",
        component: () => import("@/components/campo/CampoMapa.vue"),
        meta: { key: "campo" },
      },
      {
        path: "/app/add",
        name: "Criar Ponto",
        meta: { key: "add" },
        component: () => import("@/components/campo/CriarPonto.vue"),
        children: [
          {
            path: ":id",
            component: () => import("@/components/campo/CriarPonto.vue"),
          },
        ],
      },
      {
        path: "/meusPontos",
        name: "Meus Pontos",
        component: () => import("@/components/meus-pontos/MeusPontos.vue"),
      },
    ],
  },
  {
    path: "/login",
    name: "Login",
    component: () => import("@/views/Login.vue"),
    beforeEnter(_, __, next) {
      // Impede usuários assinados de
      if (!isSignedIn()) {
        // acessar a página de login.
        next();
        return;
      }
      next("/app/campo");
    },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  // if (to.path == "/") {
  //   router.push("/mapa");
  // }
  if (to.path == "/app/moderador") {
    let userData = JSON.parse(localStorage.getItem("userData"));
    if (userData.role) {
      if (userData.role.id != 3) {
        window.location.assign("/app/campo");
      }
    }
  }

  next();
});

// router.beforeEach((to, from, next) => {
//   if (to.path != '/login' && to.path != '/'){
//     const existsSessionAuth = window.sessionStorage.getItem('auth')

//     if(existsSessionAuth === 'false' || !existsSessionAuth){
//       window.location.assign('/login')
//     }

//   }
//   next()
// })

export default router;
