// import decode from 'jwt-decode';
import request from "./router/requests";

export async function signIn(identifier, password) {
  const resposta = await request("POST", "auth/local", {
    identifier,
    password,
  });

  if (!resposta.error) {
    localStorage.setItem("token", resposta.jwt);
    const resp2 = await request("GET", "users/me?populate=*");
    localStorage.setItem("userData", JSON.stringify(resp2));
    return true;
  }
  return false;
}

export function signOut() {
  localStorage.removeItem("token");
  localStorage.removeItem("userData");
}

export function isSignedIn() {
  const token = localStorage.getItem("token");
  if (token) {
    return true;
  }
  return false;
}

export function isAdmin() {
  const user = JSON.parse(localStorage.getItem("userData"));
  if (user.role.type == "admin_350" || user.role.type == "super_admin") {
    return true;
  } else {
    return false;
  }
}
