import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

import pt from 'vuetify/src/locale/pt.ts'

export default new Vuetify({
  lang: {
    locales: { pt },
    current: 'pt',
  },
  theme: {

    themes: {
      light: {
        primary: '#002A51',
        secondary: '#e7e3e3',
        accent: '#0B0033',
        verde: '#82BA32',
        azul: '#3094cf',
        error: '#b71c1c',
      }
    }
  }

});
